body {
	background-color: #fff;
	color: #a5a5a5;
	font-family: libre-franklin, Arial, sans-serif;
	font-size: 15px;
	font-weight: 300;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.page-controls {
	position: absolute;
	bottom: 5%;
	left: 50%;
	background: white;
	opacity: 0;
	transform: translateX(-50%);
	transition: opacity ease-in-out 0.2s;
	box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
	border-radius: 4px;
}

.react-pdf__Document {
	position: relative;
}

.react-pdf__Document:hover .page-controls {
	opacity: 1;
}
.react-pdf__Document:hover {
	cursor: zoom-in;
}

.page-controls span {
	font: inherit;
	font-size: 0.8em;
	padding: 0 0.5em;
}

.page-controls button {
	width: 44px;
	height: 44px;
	background: white;
	border: 0;
	font: inherit;
	font-size: 0.8em;
	border-radius: 25px;
}
.page-controls button:enabled:hover {
	cursor: pointer;
}

.page-controls button:enabled:hover,
.page-controls button:enabled :hover :focus {
	background-color: #e6e6e6;
}

.page-controls button:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.page-controls button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
