.rbc-event {
	pointer-events: none;
	padding: 0;
}

.rbc-header {
	pointer-events: none;
}

.rbc-day-slot .rbc-events-container {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	margin-right: 0;
	top: 0;
}

.event-style {
	/* width: 100; */
	border-radius: 0;
	text-align: center;
	justify-content: center;
}

.rbc-day-slot .rbc-event-content {
	width: 100%;
	flex: 1 1;
	word-wrap: break-word;
	line-height: 0.4;
	height: 100%;
	min-height: 1em;
	display: flex;
	font-family: Arial, Helvetica, sans-serif;
}
.schedule-image {
	height: 40px;
	padding-left: 5px;
}

.schedule-image-box {
	position: relative;
	text-align: right;
	bottom: 85px;
}

.rbc-allday-cell {
	display: none;
}

.dont-print {
	display: none;
}

@media print {
	@page {
		size: landscape;
	}
	.printable-schedule {
		margin: 0.5in;
		/* margin: .5in 0in 0in .5in !important; */
		height: 8.5in;
		width: 11in;
	}
	.schedule-image {
		height: 25px !important;
	}
	.rbc-timeslot-group {
		border-bottom: 1px solid #ddd;
		display: flex;
		flex-flow: column nowrap;
		min-height: 0px !important;
	}
	.schedule-image-box {
		position: relative;
		text-align: right;
		bottom: 10% !important;
	}
	.rbc-time-view {
		display: flex;
		flex-direction: column;
		flex: 1 1;
		width: 100%;
		min-height: fit-content !important;
	}
	.eventText {
		font-size: 4px !important;
		font-weight: bold;
		color: white;
		text-align: center;
		flex: 1 1 0%;
		align-items: center;
		justify-content: center;
		line-height: 1.3;
	}
	.headerTitle {
		height: 28px;
		align-items: flex-start;
	}
	.textTitle {
		font-size: 18px;
	}
	.rbc-header > a,
	.rbc-header > a:active,
	.rbc-header > a:visited {
		color: inherit;
		text-decoration: none;
		font-size: 5px;
	}
	.rbc-time-content {
		display: flex;
		flex: 1 0;
		align-items: flex-start;
		width: 100%;
		/* border-top: 2px solid #DDD; */
		overflow-y: auto;
		position: relative;
	}
	.rbc-timeslot-group {
		/* border-bottom: 1px solid #DDD; */
		min-height: 40px;
		display: flex;
		flex-flow: column nowrap;
	}
	.printable-schedule {
		page-break-after: always;
	}
	.TeamScheduleView {
		display: none;
	}
	.dont-print {
		display: block;
	}
	.rbc-time-header-content {
		height: 10px;
	}
	.schedule-image {
		height: 20px !important;
		padding-top: 0px !important;
	}
}
