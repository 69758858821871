.multigame .sport {
	display: none;
}

.multigame:hover .sport {
	display: block;
}
@media print {
	.gamecell-colorchart {
		font-size: 8px;
	}
}
