/* .App {
  text-align: center;
} */

.stuff {
	background: white;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-float infinite 3s ease-in-out;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-link {
	color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

.amplify-input {
	font-size: 0.5em;
}

div[data-amplify-authenticator] [data-amplify-container] {
	place-self: normal;
	justify-self: center;
}

.customauth {
	top: 2vh;
	z-index: 1;
	position: relative;
}

iframe {
	display: none;
}

.amphead {
	background: url(./JCCBackground.jpg) !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	width: 100vw;
	height: 100vh;
	top: -2vh;
	left: 0;
	z-index: -50;
	position: absolute;
}
