.gm__option {
	cursor: pointer !important;
}

.node.tree.disabled.parent-node label {
	color: #404040;
	cursor: pointer;
}
.node.tree.disabled.parent-node label input {
	display: none;
	/* content: ""; */
	/* position: absolute;
      width: 100%;
      height: 16px;
      left: 0;
      right: 0; */
}
