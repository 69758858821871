.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	min-height: 100vh !important;
	z-index: 100;
	padding: 20px 0 0;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	overflow-y: auto;
	max-width: 15vw;
}
#sidebar-wrapper {
	/* min-height: 99vh !important; */
	/* width: 80vw; */
	margin-left: -1rem;
	-webkit-transition: margin 0.25s ease-out;
	-moz-transition: margin 0.25s ease-out;
	-o-transition: margin 0.25s ease-out;
	transition: margin 0.25s ease-out;
	/* max-width: 10vw; */
	/* overflow: hidden;
    position: sticky */
}
#sidebar-wrapper .sidebar-heading {
	/* padding: 0.6rem 1.25rem; */
	padding: 0.8rem;
	/* font-size: 1.2rem; */
	color: white;
	text-decoration: none;
}

.main-nav {
	background-color: #4066d4;
}

#page-content-wrapper {
	min-width: 0;
	width: 100%;
	align-content: center;
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

#custom-nav-top {
	-webkit-box-shadow: 0 8px 6px -6px #999;
	-moz-box-shadow: 0 8px 6px -6px #999;
	box-shadow: 0 8px 6px -6px #999;
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
}
