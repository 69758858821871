.mytooltip {
	display: none;
}

.flag:hover + .mytooltip {
	display: inline;
}

.signers {
	display: none;
}

.score:hover + .signers {
	display: inline;
}
