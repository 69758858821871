nav {
	position: sticky;
	position: -webkit-sticky; /* For Safari */
	top: 24px; /* How far down the page you want your ToC to live */

	/* Give table of contents a scrollbar */
	max-height: calc(100vh - 40px);
	overflow: auto;
}

.heading-item {
	color: black;
}
.heading-li {
	border-bottom-width: 0.5px;
	border-style: solid;
	border-bottom-color: black;
}

.heading-class {
	/* border: 1px solid grey; */
	list-style-type: none;
	/* border-width: 1px;
    border-color: black;
    border-radius: 10px; */
}
